import React, { useEffect, forwardRef, useCallback } from "react";
import { createPortal } from "react-dom";
// in order for the parent to change this local state, you'll have to call up the "refs"
// forwardRef is used to forward the reference to the Modal component.
// useImperativeHandle is used to expose methods to the parent component.
import amazon from "../images/download/Amazon.png";
import apple from "../images/download/Apple.svg.png";

const Modal = forwardRef(({ isOpen, setIsOpen, view, french }, ref) => {
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) setIsOpen(false);
    },
    [setIsOpen]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return createPortal(
    isOpen ? (
      <div ref={ref} className="modal">
        <div className="modal__wrapper" onClick={close} />
        <div className="modal__content">
          <h1
            role="button"
            className="modal-close"
            aria-label="close"
            onClick={close}
          >
            X
          </h1>
          <div className="modal__title">
            <h1>{view.title}</h1>
            <p>{french ? view.creditsfr : view.creditseng}</p>
            <div className="modal__title--links">
              <div>
                <p>{french ? "télécharger sur : " : "download it on : "}</p>
              </div>
              <a href={view.amazon} target="_blank" rel="noreferrer">
                <span>Amazon : </span>
                <img src={amazon} alt="Amazon" />
              </a>
              <a href={view.apple} target="_blank" rel="noreferrer">
                <span>Apple : </span>
                <img style={{ height: "1rem" }} src={apple} alt="iTunes" />
              </a>
            </div>
            <div>
              <a
                href={french ? view.orderFr : view.orderEng}
                target="_blank"
                rel="noreferrer"
              >
                <h4>{french ? "Commander le CD" : "Order the CD"}</h4>
              </a>
            </div>
          </div>
          {view.deezer ? (
            <iframe
              title="deezer-widget"
              src={`https://widget.deezer.com/widget/light${view.deezer}`}
              width="100%"
              height="500"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media; clipboard-write"
            ></iframe>
          ) : (
            <div className="modal__tracklist">
              <img src={view.image} alt={view.image} />
              <div className="tracklist">
                <div>{view.tracklist}</div>
                <div className="modal__sounds">
                  {view.sounds.map((e, i) => {
                    return (
                      <div>
                        <figure key={i}>
                          <audio controls src={e}>
                            Your browser does not support the
                            <code>audio</code> element.
                          </audio>
                        </figure>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {french ? view.comentfr : view.comenteng}
        </div>
      </div>
    ) : null,
    document.getElementById("modal-root")
  );
});

export default Modal;
