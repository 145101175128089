import React from "react";

const Footer = () => {
  return (
    <div className="container__footer">
      <footer>
        <a
          href="https://www.facebook.com/claudinefrancoismusic"
          target="_blank"
          rel="noreferrer"
        >
          <h5>FACEBOOK</h5>
        </a>
        <a
          href="https://www.reverbnation.com/claudinefrancois"
          target="_blank"
          rel="noreferrer"
        >
          <h5>REVERBNATION</h5>
        </a>
        <a
          href="https://www.youtube.com/user/claudinefrancoisjazz"
          target="_blank"
          rel="noreferrer"
        >
          <h5>YOUTUBE</h5>
        </a>
      </footer>
    </div>
  );
};

export default Footer;
