import bio from "../images/Bio/CFProfil-Inspire.jpg";

const Bio = ({ french }) => {
  return (
    <div className="container">
      <div className="container__page">
        <div>
          <div className="bio--top">
            <img src={bio} alt="Claudine François piano" />
            <h5>Photo : Franck Vorgers</h5>
          </div>
          {french ? (
            <>
              <p>
                Pianiste talentueuse, musicienne de nombreuses aventures
                (classique, musique improvisée, free jazz, standards, latin,
                Europe centrale, Afrique, Caraïbes …), Claudine François est une
                artiste aux multiples facettes, jouant dans l'expression de son
                art tantôt d'influences africaines et caribéennes, tantôt du
                courant afro-américain post-bop, sans renier ses racines
                européennes. En dehors de sa discographie elle a joué avec Bobby
                Few, Itaru Oki, Serge Tamas, Roger Raspail, Özay Fetch, Leïla
                Soldevila, Denis Colin, Bruno Toccane, Mike Ellis, Aldridge
                Hansberry, Glenn Ferris, Carlos Barretto, Carlos Gbaguidi, Ray
                Doumbe, Guy Nwogang et de nombreux autres musiciens de toutes
                cultures.
              </p>
              <p>
                Après une brillante formation classique et une carrière de
                concertiste, parallèle à l'enseignement de la musique (lycée,
                ateliers de création pour enfants, adolescents et adultes), elle
                se tourne vers la musique improvisée et le jazz, suite entre
                autre au déclic provoqué par un mémorable concert de l'Art
                Ensemble of Chicago à Paris.
              </p>
              <p>
                Elle participe alors à des ateliers jazz avec Jacques Thollot,
                Daniel Humair, Henri Texier, fonde un groupe de free jazz et
                obtient un prix de la SACEM au concours de la Défense en 1983,
                écrit pour le théatre et la danse ainsi que des recueils de
                pièces éditées chez Henry Lemoine.
              </p>
              <p>
                Elle tourne, d'abord avec un spectacle musical, Cirque de
                Barbarie, puis avec ses propres formations marquées par sa
                rencontre avec le batteur John Betsch avec qui elle enregistre
                ses albums " Camargue " (avec également le regretté saxophoniste
                Jim Pepper) et " Healing Force ", entre sensibilité européenne
                et groove américain.
              </p>
              <p>
                Son amour de la culture et des rythmes africains l'amène à se
                rapprocher de musiciens camerounais et béninois avec qui elle
                crée le Métis Quintet, dont la musique mélange jazz et sources
                africaines d'aujourd'hui. S'ensuivent des tournées en France et
                en Afrique et un album : " Amazon " en 2002 avec le saxophoniste
                Jean-Jacques &Eacute;langué, le trompettiste Nicolas Genest, le
                bassiste André Nkouaga et le percussionniste/batteur Denis
                Tchangou.
              </p>
              <p>
                Tout en poursuivant cette aventure avec le " Métis " , Claudine
                a sorti :
              </p>
              <p>
                - début 2004 " Lonely Woman " (Marge) , un album " post bop "
                signé Claudine François Quartet avec Steve Potts (sax),
                Jean-Jacques Avenel (cb) et John Betsch (drums).
              </p>
              <p>
                - Son album "Piano Solo, disponible depuis début 2010 en
                numérique chez Sergent Major.
              </p>
              <p>
                - "Oasis", en duo avec le guitariste New Yorkais Dan Rose, paru
                en 2013 chez Enja.
              </p>
              <p>
                - "Flying Eagle", en trio avec Hamid Drake (drums) et Hubert
                Dupont (Contrebasse), sorti début 2014 sur le label Futura
                Marge.
              </p>
              <p>
                - "New leaves ", en duo avec le guitariste américain Dan Rose -
                Ride symbol Records, sorti début 2020.
              </p>
            </>
          ) : (
            <>
              <p>
                A talented pianist and musician of multitudinous adventures
                (classical, improvised music, free jazz, standards, latino,
                Central Europa, African, caribbean...) Claudine François is a
                many-faceted artist who, within the expression of her art,
                incorporates African, Caribbean and African-American post-bop
                influences without ever renouncing her European roots. In
                addition to her discography she played with Bobby Few, Itaru
                Oki, Serge Tamas, Roger Raspail, Özay Fetch, Leïla Soldevila,
                Denis Colin, Bruno Toccane, Mike Ellis, Aldridge Hansberry,
                Glenn Ferris, Carlos Barretto, Carlos Gbaguidi, Ray Doumbe, Guy
                Nwogang and numerous other musicians from all cultural origines.
              </p>
              <p>
                After outstanding classical studies followed by a career as a
                concert pianist and parallel teaching activities (in high
                schools and workshops for children, adolescents and adults), she
                turned to improvisation and jazz after being exposed to other
                influences, notably the memorable performance of The Art
                Ensemble of Chicago's in Paris.
              </p>
              <p>
                In 1983 she obtained a SACEM prize at the la Défense
                competition. Claudine composes for theater, dance and has also
                put out a compilation of her works (publisher: Henry Lemoine).
              </p>
              <p>
                Claudine began touring with the musical show , "Cirque de
                Barbarie", then with her own groups after being influenced by
                the American drummer John Betsch, with whom she recorded her
                albums " Camargue " (as well as with the regretted saxophonist
                Jim Pepper) and " Healing Force " - between European sensibility
                et American groove.
              </p>
              <p>
                Her love of African cultures and rhythms has led her to
                collaborate with musicians from Cameroon and Benin with whom she
                created the Metis Quintet, a band which mixes jazz and sounds
                from contemporary African sources. The Quintet has toured in
                France and Africa. In 2002 the album " Amazon " was released
                with saxophonist Jean-Jacques élangué, trumpeter Nicolas Genest,
                bassist André Nkouaga and drummer Denis Tchangou.
              </p>
              <p>
                While continuing her adventure with the Métis Quintet, at the
                beginning of 2004 she released " Lonely Woman " (Marge) by
                Claudine François Quartet a new 'Post Bop" album with Steve
                Potts (saxophone), Jean-Jacques Avenel (double bass), and John
                Betsch (drums).
              </p>
              <p>
                The well named "Piano Solo, was digitally released in 2010 on
                the label Sergent Major.
              </p>
              <p>
                Claudine recentely worked with New Yorkan guitar player Dan Rose
                for their duet album "Oasis" (Enja 2013) and has released
                begining of 2014 the album "Flying Eagle" with Hamid Drake
                (drums) & Hubert Dupont (acoustic bass) on label Futura Marge.
              </p>
              <p></p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bio;
