import { Link } from "react-router-dom";

const Press = ({ french }) => {
  return (
    <div className="container">
      <div className="container__page">
        <div className="press__page">
          {french ? (
            <>
              <div>
                <p>
                  " Claudine François ou le piano de grâce par une musicienne
                  qui donne espace et respiration à ce qui est joué. La
                  manifestation d'une sensibilité et d'une élégance qui touchent
                  autant ses musiciens que le public. "
                </p>
                <span>Sylvain Siclier - L'Affiche</span>
              </div>
              <div className="separator"></div>
              <div>
                <p>
                  " C'est une musicienne dans la tradition d'Eric Dolphy, qui
                  écoute ses partenaires. Claudine François est une cheville
                  ouvrière qui laisse beaucoup de liberté à ses sidemen. "
                </p>
                <span>Télérama</span>
              </div>
              <div className="separator"></div>
              <div>
                <p>
                  " Voilà une formation qui n'est pas sans rappeler celle de Don
                  Pullen, tant Claudine François a du goût pour le jeu
                  rythmique, notamment en accord serrés qu'elle plaque avec une
                  énergie peu fréquente. Léo Mamey Jazz Magazine (fév 2004) sur
                  "Lonely Woman" par Claudine François. "
                </p>
                <span>Quartet</span>
              </div>
              <div>
                <div className="separator"></div>
              </div>
              <div>
                <p>
                  " Voici une musique qui a le pouvoir et la force de vous
                  emporter hors de vous-même, pour vous enlever à travers
                  l'espace... Claudine est passée brillamment dans le monde des
                  grands musiciens de jazz. Bienvenue Claudine. "
                </p>
                <span>Mal Waldron</span>
              </div>
              <div className="separator"></div>
              <div>
                <span>Claudine François sur le web : </span>
                <p>
                  Claudine François dans le Boudoir de Proust de{" "}
                  <a
                    className="link"
                    href="https://www.citizenjazz.com/Claudine-Francois-dans-le-boudoir.html"
                  >
                    Citizen Jazz
                  </a>
                </p>
              </div>
              <div className="separator"></div>
              <div>
                <Link to="/pro">
                  <p className="link">
                    Dossier de presse à télécharger au format Adobe Acrobat
                  </p>
                </Link>
              </div>
            </>
          ) : (
            <>
              <div>
                <p>
                  " Claudine François is a good composer, drawing from the
                  wellspring of jazz without succumbing to slavish imitation. "
                </p>
                <span>Gary Bannister Earshot Jazz Seattle February 1990</span>
              </div>
              <div className="separator"></div>
              <div>
                <p>
                  " Claudine often plays a gripping, sparkling, striking piano,
                  mastering many (and personal) nuances between melodious and
                  free. "
                </p>
                <span>
                  Willie Gschwendner, Jazz Podium (Germany) April 1990{" "}
                </span>
              </div>
              <div className="separator"></div>
              <div>
                <p>
                  " Nice compositions, perfect setting, overall cohesion and
                  music, a real pleasure and a band worth following. "
                </p>
                <span>Serge Baudot, Jazz Hot - addition to n°595, 2002</span>
              </div>
              <div>
                <div className="separator"></div>
              </div>
              <div>
                <p>
                  " Here is a music with the power and strength to carry you
                  outside of yourself, to transport you through space on the …
                  Claudine has graduated “cum laude” into the world of great
                  Jazz musicians. Welcome Claudine ! "
                </p>
                <span>Mal Waldron</span>
              </div>
              <div className="separator"></div>
              <div>
                <span>Claudine François on the web : </span>
                <p>
                  Claudine François in the "Boudoir de Proust" of{" "}
                  <a
                    className="link"
                    href="https://www.citizenjazz.com/Claudine-Francois-dans-le-boudoir.html"
                  >
                    Citizen Jazz
                  </a>
                </p>
              </div>
              <div className="separator"></div>
              <div>
                <Link to="/pro">
                  <p className="link">
                    Download the press book (Adobe Acrobat file)
                  </p>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Press;
