import press from "../PDF/DP_CF_Fr.pdf";
import tech1 from "../PDF/FT_CFM5.pdf";
import tech2 from "../PDF/FT_CFRANCOIS_4_100304.pdf";
import class1 from "../PDF/masterclass_improvisation.pdf";
import class2 from "../PDF/masterclass_rencontres_musicales.pdf";
import class3 from "../PDF/stage_metis_quintet.pdf";

import img from "../images/CFA.jpg";

const Pro = ({ french }) => {
  return (
    <div className="container">
      <div className="container__page--pro">
        <img src={img} alt="Claudine François" />
        <div className="pro__page">
          <span>DOSSIER DE PRESSE</span>
          <h4>
            <a className="link" href={press} target="_blank" rel="noreferrer">
              <p>Claudine François</p>
            </a>
          </h4>
          <span>MASTER CLASS</span>
          <h4>
            <a className="link" href={class1} target="_blank" rel="noreferrer">
              <p>Masterclass d'improvisation</p>
            </a>
          </h4>
          <h4>
            <a className="link" href={class2} target="_blank" rel="noreferrer">
              <p>Stage avec le Métis Quintet</p>
            </a>
          </h4>
          <h4>
            <a className="link" href={class3} target="_blank" rel="noreferrer">
              <p>Masterclass - rencontres musicales en Afrique</p>
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Pro;
