import React from "react";

const Contact = ({ french }) => {
  return (
    <div className="container">
      <div className="contact">
        <div>
          {french ? (
            <>
              <h1>
                <span>CONTACT</span>
              </h1>
              <h2>
                <span>Tél. :</span>
              </h2>
              <h3>+33 (0)6 71 66 20 68</h3>
              <h2>
                <span>Courriel :</span>{" "}
              </h2>
              <h3>
                <address>
                  <a href="mailto:contact@claudinefrancois.com">
                    contact@claudinefrancois.com
                  </a>
                </address>
              </h3>
            </>
          ) : (
            <>
              <h1>
                <span>CONTACT</span>
              </h1>
              <h2>
                <span>Tél. :</span>
              </h2>
              <h3>+33 (0)6 71 66 20 68</h3>
              <h2>
                <span>E-mail :</span>{" "}
              </h2>
              <h3>
                <address>
                  <a href="mailto:contact@claudinefrancois.com">
                    contact@claudinefrancois.com
                  </a>
                </address>
              </h3>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
