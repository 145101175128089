import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Slideshow.scss";

import slide1 from "../images/photos/slide1.jpg";
import slide2 from "../images/photos/slide2.jpg";
import slide3 from "../images/photos/slide3.jpg";
import slide4 from "../images/photos/slide4.jpg";
import slide5 from "../images/photos/slide5.jpg";
import slide6 from "../images/photos/slide6.jpg";
import slide7 from "../images/photos/slide7.jpg";
import slide8 from "../images/photos/slide8.jpg";
import slide9 from "../images/photos/slide9.jpg";
import slide10 from "../images/photos/slide10.jpg";
import slide11 from "../images/photos/slide11.jpg";
import slide12 from "../images/photos/slide12.jpg";
import slide13 from "../images/photos/slide13.jpg";
import slide14 from "../images/photos/slide14.jpg";
import slide15 from "../images/photos/slide15.jpg";
import slide16 from "../images/photos/slide16.jpg";
import slide17 from "../images/photos/slide17.jpg";
import slide18 from "../images/photos/slide18.jpg";
import slide19 from "../images/photos/slide19.jpg";
import slide20 from "../images/photos/slide20.jpg";
import slide21 from "../images/photos/slide21.jpg";
import slide22 from "../images/photos/slide22.jpg";
import slide23 from "../images/photos/slide23.jpg";
import slide24 from "../images/photos/slide24.jpg";
import slide25 from "../images/photos/slide25.jpg";
import slide26 from "../images/photos/slide26.jpg";
import slide27 from "../images/photos/slide27.jpg";

const slideImages = [
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
  slide9,
  slide10,
  slide11,
  slide12,
  slide13,
  slide14,
  slide15,
  slide16,
  slide17,
  slide18,
  slide19,
  slide20,
  slide21,
  slide22,
  slide23,
  slide24,
  slide25,
  slide26,
  slide27,
];

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Fade infinite={true} indicators={true} duration={3000}>
        {slideImages.map((e, i) => {
          return (
            <>
              <div key={i} className="each-fade">
                <div className="image-container">
                  <img
                    style={{ maxHeight: "550px", margin: "auto" }}
                    src={e}
                    alt={e}
                  />
                </div>
              </div>
            </>
          );
        })}
      </Fade>
    </div>
  );
};

export default Slideshow;
