import React from "react";
const concerts = [
  {
    date: `2020`,
    content: `Solo concert à l’AJMI (Avignon) et hôtel C2 à Marseille`,
  },
  { date: `2019`, content: `Concerts à Montréal ` },
  {
    date: `2019`,
    content: `Création en sextet à partir de musiques traditionnelles du Tchad, à l’Institut Français de N'Djamena`,
  },
  {
    date: `2011`,
    content: `Tournée avec le groupe Nawal Lithuanie, Allemagne, Autriche`,
  },
  { date: `2010`, content: `Tournée avec le groupe Nawal aux Comores` },
  { date: `2010`, content: `Tournée avec le groupe Nawal aux USA` },
  {
    date: `18-09-2009`,
    content: `CENTRE CULTUREL FRANCO-MOZAMBICAIN - Maputo, Mozambique - Concert solo avec des invités surprise`,
  },
  {
    date: `23 & 24-01-2009`,
    content: `avec CLAUDINE FRANÇOIS MÉTIS QUINTET au MUSIC HALL (ABIDJAN, Côte d'Ivoire)`,
  },
  {
    date: `28-07-2007`,
    content: `Claudine François Métis Quintet au Festival de Jazz de Serres (05700 SERRES)`,
  },
  {
    date: `26-05-2007`,
    content: `Claudine François Métis Quintet au Festival l'Orée de Chartres (Prunay le Gillon)`,
  },
  {
    date: `06-01-2007`,
    content: `Concert Latin Jazz au Palais des Congres of Cotonou "Autour de la salsa"`,
  },
  {
    date: `31-03-2006`,
    content: `Concert avec Sagbohan DANIALOU au Palais Royal de Port Novo (Benin)`,
  },
  {
    date: `25 & 26-03-2006`,
    content: `atelier avec le chanteur-percussionniste Sagbohan DANIALOU au Centre Culturel Français de Cotonou`,
  },
  {
    date: `05-08-2005`,
    content: `Claudine François Métis Quintet - Festival JAZZ EN AOÛT (F-13600 LA CIOTAT)`,
  },
  {
    date: `1997`,
    content: `Festival « Jazz sous les Pommiers » à Coutances, festival « Jazz sous les Manguiers » à Yaoundé (Cameroun)`,
  },
  {
    date: `Et aussi`,
    content: `De nombreux concerts à la Maison de la Radio à Paris`,
    more: `Festival d’Auvers s/Oise, Festival « Jazz sous les Pommiers » à Coutances`,
  },
];

const Concerts = ({ french }) => {
  return (
    <div className="container">
      <div className="container__page--concert">
        <div className="concerts">
          <h1>
            <span>CONCERTS</span>
          </h1>
          <div>
            {concerts.map((e, i) => {
              return (
                <div className="guestbook__messages" key={i}>
                  <h2>♫ - {e.date} :</h2>
                  <p>{e.content}</p>
                  <p>{e.more}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Concerts;
