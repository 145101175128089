import frenchFlag from "../images/Flag_of_France.svg.webp";
import englishFlag from "../images/Flag_of_the_United_Kingdom.svg.webp";
import pianorouge from "../images/pianorouge.jpg";

const Header = ({ setFrench, french }) => {
  const handleLanguage = () => {
    setFrench(!french);
  };
  return (
    <div className="container__header">
      <header>
        <div className="header__title">
          <h1>
            <span>Claudine François</span>
          </h1>
        </div>
        <div onClick={handleLanguage} className="header__flag">
          <h2>{french ? "English" : "Français"}</h2>
          <img src={french ? englishFlag : frenchFlag} alt="Language" />
        </div>
      </header>
    </div>
  );
};

export default Header;
