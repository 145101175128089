import { useRef, useState } from "react";

import Modal from "../components/Modal";

import deezerLogo from "../images/download/Deezer_logo.svg.png";
import spotifyLogo from "../images/download/Spotify_logo.svg.png";
import Amazon from "../images/albumcovers/Amazon.jpg";
import Camargue from "../images/albumcovers/Camargue.jpg";
import CommeSi from "../images/albumcovers/CommeSi.jpg";
import FlyingEagle from "../images/albumcovers/FlyingEagle.jpg";
import HealingForce from "../images/albumcovers/HealingForce.jpg";
import LonelyWomen from "../images/albumcovers/Lonely-Woman.jpg";
import NewLeaves from "../images/albumcovers/NewLeaves.jpg";
import oasis from "../images/albumcovers/oasis.jpg";
import pianoSolo from "../images/albumcovers/piano-solo.jpg";
import * as camargue from "../media/camargue/camargue.js";
import * as lonelywoman from "../media/lonelyWoman/lonelyWoman.js";
import * as amazon from "../media/amazon/amazon.js";
import * as healingForce from "../media/healingForce/healingForce.js";
import * as commeSi from "../media/commeSi/commeSi.js";
import commande from "../PDF/commande_CF_fr.pdf";
import order from "../PDF/order_CF.pdf";

const disco = [
  {
    title: "New Leaves",
    edit: "(Ride symbol Records/2020)",
    creditsfr: "avec le guitariste américain Dan Rose",
    creditseng: "with the american guitarist Dan Rose",
    image: NewLeaves,
    deezer: "/album/129558552",
    spotify: "https://open.spotify.com/album/5ckSt0s70g8FDInRtIez6g",
  },
  {
    title: "Flying Eagle",
    edit: "(Marge/2014)",
    image: FlyingEagle,
    deezer: "/album/87082282",
    spotify: "https://open.spotify.com/album/3Vt1RSFoec7UQqAe4FCZuh",
    apple: "https://music.apple.com/fr/album/scot/1452275899?i=1452276344",
    creditsfr: "avec Hubert Dupont (contrebasse) et Hamid Drake (batterie)",
    creditseng: "with Hubert Dupont (bass) and Hamid Drake (batterie)",
    comentfr: (
      <div>
        <p>
          <b>Tapiwa's vision</b> (Claudine François)
          <br />
          Un portrait musical de mon ami sculpteur et peintre du Zimbabwe Tapiwa
          Chapo, de l'ethnie shona.
        </p>
        <p>
          <b>African Flower</b> (Duke Ellington)
        </p>
        <p>
          <b>Nkosi sikelele Afrika</b> (Enoch Sontonga, Samuel Mqhayi)
          <br />
          Le chant qui était l'hymne de l'ANC au temps de l'apartheid et a été
          repris dans de nombreuses langues.
        </p>
        <p>
          <b>Remember Mr. PM</b> (Claudine François)
          <br />
          Un hommage au grand batteur Paul Motian, qui joua beaucoup avec Jim.
        </p>
        <p>
          <b>Visage Pâle</b> (Hubert Dupond)
        </p>
        <p>
          <b>Flying Eagle</b> (Claudine François)
          <br />
          Composé en hommage à Jim Pepper dont c'est le nom Indien.
        </p>
        <p>
          <b>Scot</b> (Hubert Dupond)
        </p>
        <p>
          <b>Double Dutch Treat </b> (Claudine François)
          <br />
          Un jeu de saut à la corde avec de nombreuses figures.
        </p>
        <p>
          <b>Witchi tia To</b> (Jim Pepper)
          <br />
          Une chanson traditionnelle (un chant du peyote appris de son
          grand-père) que Hamid a enregistrée avec Jim autrefois et que j'ai
          beaucoup jouée avec lui par la suite.
        </p>
        <p>
          Un jour Gérard Terronès m'appela pour me proposer un projet avec Hamid
          Drake, avec qui il avait déjà travaillé à plusieurs reprises : il ne
          savait pas que Jim Pepper nous avait présentés l'un à l'autre il y a
          bien longtemps, sans que nous ayons eu l'occasion de travailler
          ensemble ... Mais bien que nous ayons eu très peu de temps pour le
          faire, je suis très heureuse d'avoir pu enregistrer cet album, un
          hommage a Jim pepper et à la liberté en général. Mes remerciements à
          Gérard, Marianne et Odile, John Betsch.
        </p>
        <p>
          <em>Claudine François</em>
        </p>
      </div>
    ),
    comenteng: (
      <div>
        <p>
          <b>Tapiwa's vision</b> (Claudine François)
          <br />A musical portrait of my friend Tapiwa Chapo, sculptor and
          artist from Zimbabwe, of the shona tribe.
        </p>
        <p>
          <b>African Flower</b> (Duke Ellington)
        </p>
        <p>
          <b>Nkosi sikelele Afrika</b> (Enoch Sontonga, Samuel Mqhayi)
          <br />
          The song was the anthem for ANC during apartheid and was sung in many
          african languages .
        </p>
        <p>
          <b>Remember Mr. PM</b> (Claudine François)
          <br />A tribute to great drummer Paul Motian, who played a lot with
          Jim.
        </p>
        <p>
          <b>Visage Pâle</b> (Hubert Dupond)
        </p>
        <p>
          <b>Flying Eagle</b> (Claudine François)
          <br />
          This is Jim's indian name, I wrote the tune as a tribute to him.
        </p>
        <p>
          <b>Scot</b> (Hubert Dupond)
        </p>
        <p>
          <b>Double Dutch Treat </b> (Claudine François)
          <br />A play of skipping ropes with many variations.
        </p>
        <p>
          <b>Witchi tia To</b> (Jim Pepper)
          <br />A traditional song (peyote song he had learned from his
          grandfather) that Jim recorded with Hamid and that I played later on
          with Jim .
        </p>
        <p>
          One day Gerard Terrones proposed to me a new project with Hamid. He
          had been recording Hamid several times before but didn't know Hamid
          and I had been introduced to each other by Jim Pepper a long time ago
          although we never had the opportunity to work together ... We had very
          little time and I am glad we could do this as a tribute to Jim Pepper
          and to freedom in general.
        </p>
        <p>
          <em>Claudine François</em>
        </p>
      </div>
    ),
  },
  {
    title: "Oasis",
    edit: "(Enja/2013)",
    creditsfr: "avec le guitariste et compositeur Dan Rose",
    creditseng: "with guitar player & composer Dan Rose",
    image: oasis,
    deezer: "/album/6684357",
    spotify: "https://open.spotify.com/album/2XU0cybqayURTDHngU0yt9",
    amazon: "http://www.amazon.fr/gp/product/B0046P0CZS/",
    apple: "https://itunes.apple.com/fr/album/oasis/id657755908",
    orderFr: commande,
    orderEng: order,
  },
  {
    title: "Piano Solo",
    edit: "(Sergent Major/2010)",
    image: pianoSolo,
    deezer: "/album/476622",
    spotify: "https://open.spotify.com/album/3nwXn6QkeABIX13GuMPBZ2",
    amazon: "https://www.amazon.fr/dp/B00339X2TQ",
    apple: "https://music.apple.com/fr/album/piano-solo/349536314",
    orderFr: commande,
    orderEng: order,
  },
  {
    title: "Lonely Woman",
    edit: "(Marge/2004)",
    creditsfr:
      "avec Steve Potts (sax), Jean-Jacques Avenel (contrebasse) et John Betsch (drums)",
    creditseng:
      "with Steve Potts (sax), Jean-Jacques Avenel (acoustic bass) & John Betsch (drums)",
    image: LonelyWomen,
    sounds: [
      lonelywoman.twoforone,
      lonelywoman.something,
      lonelywoman.lawyears,
      lonelywoman.theseagulls,
      lonelywoman.lonelywoman,
      lonelywoman.uglybeauty,
      lonelywoman.flyingeagle,
    ],
    tracklist: (
      <div>
        <p>1. Two for one </p>
        <p>2. Something about you </p>
        <p>3. Law years </p>
        <p>4. The Seagulls of Kristiansund </p>
        <p>5. Lonely Woman</p>
        <p>6. Ugly Beauty</p>
        <p>7. Flying eagle </p>
      </div>
    ),
    comentfr: (
      <div>
        <p>
          Lorsque Gérard Terrones me proposa de faire un nouvel album pour son
          label Marge, j'ai éprouvé à la fois un sentiment de très agréable
          surprise, et celui que tout était dans l'ordre, puisque nous nous
          connaissons et nous apprécions depuis si longtemps.
        </p>
        <p>
          Quant aux musiciens qui m'accompagnent dans cette aventure, c'est tout
          aussi naturellement que j'ai demandé à des compagnons de longue date
          s'ils étaient partants : merci à tous trois, Steve Potts, Jean-Jacques
          Avenel, John Betsch, de m'avoir dit oui.
        </p>
        <p>
          La présence avec nous de nos amis et partenaires disparus Jim Pepper
          et Mal Waldron s'imposait, ainsi que celle de nos grands inspirateurs
          Monk et Ornette Coleman.
        </p>
        <p>
          J'ai écrit " Something about you " il y a longtemps et ai déjà eu
          l'occasion de le jouer avec Jean-Jacques et John, c'était donc une
          bonne occasion de l'enregistrer avec eux. Quant à " Flying Eagle ",
          c'est un hommage à notre ami et partenaire saxophoniste Jim Pepper
          dont c'est le nom indien. Jim nous a quittés depuis quelques années
          mais son esprit est toujours avec nous.
        </p>
        <p>
          La composition de Steve, " Two For One ", présente une construction
          originale et ludique, comme son titre l'indique.
        </p>
        <p>
          J'ai toujours aimé les mélodies magnifiques d'Ornette Coleman et me
          suis régalée en jouant " Lonely Woman " et " Law Years " , deux parmi
          mes préférées.
        </p>
        <p>
          Les accords uniques et surprenants de Monk prennent dans " Ugly Beauty
          " l 'apparence d'une valse lente aux détours inattendus.
        </p>
        <p>
          La dernière pièce, " The Seagulls of Kristiansund ", par mon " Parrain
          " Mal Waldron, est d'une simplicité si parfaite qu'on peut dire que
          c'est l'œuvre d'un génie.
        </p>
        <p>
          <em>Claudine François.</em>
        </p>
      </div>
    ),
    comenteng: (
      <div>
        <p>
          When Gérard Terrones proposed me for a new recording on his label
          Marge, my feeelings were alltogether of great surprise, and of being
          in the natural course of things, since we have known and appreciated
          each other for so long.
        </p>
        <p>
          As for which musicians were to be with me in this adventure, I also
          naturally asked my long time companions Steve Potts, Jean-Jacques
          Avenel, John Betsch : thanks to them for answering yes.
        </p>
        <p>
          Late friends and partners Jim Pepper and Mal Waldron's presence with
          us appeared to me as an evidence, as well as Monk's and Ornette
          Coleman's, our great inspirators.
        </p>
        <p>
          I wrote " Something about you " a long time ago and had already played
          it with Jean-Jacques and John, it was a good opportunity to record it
          with them. As for "Flying Eagle", it is a tribute to our friend and
          partner saxophonist Jim Pepper, and his native American name. Jim left
          us some years ago, but his spirit is always with us.
        </p>
        <p>
          Steve's "Two For One" is fun and has an original construction, as the
          title says.
        </p>
        <p>
          I have always loved Ornette Coleman's gorgeous melodies and enjoyed
          playing "Lonely Woman" and "Law Years", two among my favorites.
        </p>
        <p>
          Monk's unique and surprising changes take in my "Ugly Beauty" the
          appearence of a slow walz with unexpected turns.
        </p>
        <p>
          The last piece, " The Seagulls of Kristiansund ", by my " Godfather "
          Mal Waldron, is so simply perfect that one can say it is a genius's
          work.
        </p>
        <p>
          <em>Claudine François.</em>
        </p>
      </div>
    ),
  },
  {
    title: "Amazon",
    edit: "(Autoproduction/2002)",
    creditsfr:
      "avec le Métis Quintet : Jean-Jacques Elangué (sax), Nicolas Genest (trompette-bugle), Denis Tchangou (batterie) & André Nkouaga (contrebasse)",
    creditseng:
      "with the Metis Quintet : Jean-Jacques Elangué (sax), Nicolas Genest (trumpet-flugelhorn), Denis Tchangou (drums) & André Nkouaga (double bass)",
    image: Amazon,
    sounds: [
      amazon.Djien,
      amazon.Ndip,
      amazon.Tireli,
      amazon.AxeLourd,
      amazon.SongForDon,
      amazon.WonWon,
      amazon.Amazon,
      amazon.Africa,
    ],
    tracklist: (
      <div>
        <p>1. Djien </p>
        <p>2. Ndip </p>
        <p>3. Tireli </p>
        <p>4. Axe Lourd </p>
        <p>5. Song For Don</p>
        <p>6. Won Won</p>
        <p>7. Amazon</p>
        <p>8. Africa</p>
      </div>
    ),
    spotify: "https://open.spotify.com/album/55kDyZLHWrX7JV2tlVciNT",
    // amazon:
    //   "https://www.amazon.fr/Amazon/dp/B0047AHRIC?SubscriptionId=AKIAJFLV2FVFMWANJPRA&tag=deezer-21&linkCode=xm2&camp=2025&creative=165953&creativeASIN=B0047AHRIC",
    // apple: "https://music.apple.com/fr/album/amazon/398554033",
    orderFr: commande,
    orderEng: order,
    comentfr: (
      <div>
        <p>
          Merci à tous ceux qui ont gardé foi en moi et dans ma musique et m'ont
          encouragé, spécialement à Pierre pour son aide inconditionnelle.
        </p>
        <p>
          Remerciements spéciaux à Brice pour l'inspiration qu'il m'a apportée
          au travers des années, et à tous les membres permanents et temporaires
          du FAC (Foyer Annexe Camerounais) !
        </p>
        <p>
          Vivre dans un environnement multiculturel ouvre de nombreuses portes,
          menant à la joie sans fin de découvrir la variété infinie de la
          créativité humaine dans tous les aspects de la vie, tout comme aussi
          la compréhension mutuelle et l'illumination au lieu de … la guerre.
        </p>
        <p>
          <em>Claudine François.</em>
        </p>
      </div>
    ),
    comenteng: (
      <div>
        <p>
          Thanks to all who kept faith in me and my music and encouraged me,
          especially to Pierre for his unconditional help.
        </p>
        <p>
          Special thanks to Brice for the inspiration he brought to me over the
          years, and to all the FAC permanent or temporary members !
        </p>
        <p>
          Living in a multicultural environment opens many doors, leading to the
          endless treat of discovering the infinite variety of human creativity
          in all aspects of life, as well as to mutual understanding and
          enlightment instead of...war.
        </p>
        <p>
          <em>Claudine François.</em>
        </p>
      </div>
    ),
  },
  {
    title: "Healing Force",
    edit: "(PAN MUSIC/1992)",
    image: HealingForce,
    // deezer: "/album/678851",
    sounds: [
      healingForce.OneStepAbove,
      healingForce.TheHealingForce,
      healingForce.LeDesert,
      healingForce.LifeIsABitch,
      healingForce.SevenForYao,
      healingForce.TrapezeSong,
      healingForce.Corinne,
      healingForce.OlaOuKale,
    ],
    tracklist: (
      <div>
        <p>1. One Step Above</p>
        <p>2. The Healing Force</p>
        <p>3. Le Désert</p>
        <p>4. Life Is A Bitch</p>
        <p>5. Seven For Yao</p>
        <p>6. Trapeze Song</p>
        <p>7. Corinne</p>
        <p>8. Ola Ou Kalé</p>
      </div>
    ),
    spotify: "https://open.spotify.com/album/1RX6tAP5WBQ2qV30M3pZYu",
    creditsfr:
      "avec Didier Forget (Sax), Jane Bunnett (sax soprano, flûte), Harry Gofin (basse), John Betsch (batterie)",
    creditseng:
      "with Didier Forget (Sax), Jane Bunnett (sax soprano, tenor), Harry Gofin (bass), John Betsch (drums)",
    // amazon: "https://www.amazon.fr/gp/product/B0046P0CZS/",
    // apple: "https://music.apple.com/fr/album/healing-force/397591917",
  },
  {
    title: "Camargue",
    edit: "(PAN MUSIC/1989)",
    image: Camargue,
    sounds: [
      camargue.enlightment,
      camargue.camargue,
      camargue.lestortues,
      camargue.magnetic,
      camargue.hotseptember,
      camargue.icouldnt,
      camargue.double,
      camargue.crepuscule,
      camargue.hooray,
      camargue.suitex,
      camargue.drakeumba,
    ],
    tracklist: (
      <div>
        <p>1. Enlightment</p>
        <p>2. Camargue</p>
        <p>3. Les Tortues</p>
        <p>4. Magnetic Highlands (for Philippe and Sophie)</p>
        <p>5. Hot September</p>
        <p>6. I couldn't believe it</p>
        <p>7. Double Dutch Treat (for little girls everywhere)</p>
        <p>8. Crépuscule for Nellie</p>
        <p>9. Hoo Ray for Herbie</p>
        <p>10. Suite X</p>
        <p>11. Drakumba</p>
      </div>
    ),
    comentfr: (
      <div>
        <p>
          En étant sollicité pour écrire ces lignes, je me suis senti très
          honoré d'avoir l'occasion d'être partie prenante de cette merveilleuse
          aventure. Et j'emploie le mot "aventure " intentionnellement, car ici
          la musique n'est pas de celle que vous pourriez utiliser comme fond
          sonore pour faire autre chose. Au contraire, c'est une musique qui a
          la force et l'énergie pour vous transporter hors de vous-même, vous
          enlever à travers l'espace sur les ailes du son puissant et magnifique
          du saxophone de Jim Pepper.
        </p>
        <p>
          Mais laissez-moi, si vous voulez bien, tourner maintenant le
          projecteur sur l'architecte qui a façonné ces superbes structures
          sonores. Son nom est Claudine François et elle est totalement engagée
          dans la musique. Depuis le majestueux thème d'ouverture. "Enlightment
          ", en passant par la rythmique intéressante des "Tortues ", la façon
          joyeuse de traiter " Crépuscule with Nellie "de Monk, l'approche
          ouverte et libre de " Hoo Ray for Herbie ", elle domine complètement
          la situation.
        </p>
        <p>
          Elle a avec elle deux des meilleurs accompagnateurs dans le monde du
          jazz d'aujourd'hui. Je parle d'expérience car, outre que nous ayons le
          même anniversaire (bien que d'une année différente) nous employons la
          même section rythmique, à savoir le fantastique Ed Schuller et le
          formidable John Betsch. Et enfin, mais surtout pas le dernier, le
          merveilleux Jim Pepper. Avec cet album, Claudine François est passée
          avec succès dans le monde des grands musiciens du jazz. Bienvenue
          Claudine!
        </p>
        <p>
          <em>Mal Waldron (préface du livret de "Camargue").</em>
        </p>
      </div>
    ),
    comenteng: (
      <div>
        <p>
          On being approached to write the liner notes, I felt myself very
          honoured at having the chance to be a part of this wonderful
          adventure. And I use the word "adventure" advisedly for the music here
          is not the kind that you can use as a background while you do other
          things. Instead here is a music with the power and strength to carry
          you outside of yourself, to transport you through space on the wings
          of the big beautiful sound of Jim Pepper's saxophone.
        </p>
        <p>
          But let me, if you will, now turn the spotlight on the architect who
          bas fashioned this tremendous structure of sound. Her name Claudine
          François and she is totally involved in music. From the opening
          majestic theme of Enlightment through the rhythmically interesting Les
          Tortues, the playful treatment of Monk's Crepuscule with Nellie, the
          open free approach to Hurray for Herbie, she is completely on top of
          the situation.
        </p>
        <p>
          She has with her two of the best rhythm sidemen in the world Jazz
          today. I speak from experience because besides having the same
          birthday (although another year) we also have been using the same
          rhythm section consisting of the fantastic Ed Schuller and the
          formidable John Betsch. And last but definitely not least is the
          wonderful warm Jim Pepper.
        </p>
        <p>
          With this album Claudine François has graduated "cum laude" into the
          world of great Jazz musicians. Welcome Claudine!{" "}
        </p>
        <p>
          <em>Mal Waldron (for the leaflet of Camargue).</em>
        </p>
      </div>
    ),
  },
  {
    title: "Comme Si",
    edit: "(NIGLO/1984)",
    image: CommeSi,
    sounds: [
      commeSi.Lagunes,
      commeSi.GreenCats,
      commeSi.Paysage,
      commeSi.LePtitRetro,
      commeSi.CommeSi,
      commeSi.LesPetitsCafes,
      commeSi.BluesForMaPuce,
    ],
    tracklist: (
      <div>
        <p>1. Lagunes</p>
        <p>2. Green Cats</p>
        <p>3. Paysage avec une pomme verte</p>
        <p>4. Le p'tit rétro</p>
        <p>5. Comme si</p>
        <p>6. Les petits cafés blêmes des matins crèmes</p>
        <p>7. Blues for Ma Puce</p>
      </div>
    ),
    // deezer: "/album/678929",
    spotify: "https://open.spotify.com/album/1MQEU6vDHFHBA1JtlghFD9",
    // amazon: "https://www.amazon.fr/Comme-si/dp/B0046P2NEQ",
    // apple: "https://music.apple.com/fr/album/comme-si/397591445",
    orderFr: commande,
    orderEng: order,
    creditsfr:
      "avec Jean Querlier (Sax, hautbois, flûte), Bruno Girard (Violon), Jean-Louis Méchali (batterie), Pierre Jacquet (contrebasse)",
    creditseng:
      "with Jean Querlier (Sax, hautbois, flute), Bruno Girard (Violin), Jean-Louis Méchali (drums), Pierre Jacquet (acoustic bass)",
  },
];

const Disco = ({ french }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState({});

  const modal = useRef();
  //creates a reference to hold the element.
  const handleModal = (obj) => {
    setView(obj);
    setIsOpen(true);
  };

  return (
    <>
      <div className="container">
        <div className="container__page">
          <div className="disco__page">
            {disco.map((e, i) => {
              return (
                <>
                  <div key={i} className="disco__item">
                    <h2>{e.title}</h2>
                    <p>{e.edit}</p>
                    <div>
                      <img
                        onClick={() => handleModal(e)}
                        src={e.image}
                        alt={e.title}
                        className="disco__img"
                      />
                      <div>
                        {e.deezer && (
                          <div>
                            <h4>
                              <a
                                href={`https://www.deezer.com/fr${e.deezer}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="disco__logo--deezer"
                                  src={deezerLogo}
                                  alt="Deezer"
                                />
                              </a>
                            </h4>
                          </div>
                        )}
                        {e.spotify && (
                          <div>
                            <h4>
                              <a
                                href={e.spotify}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="disco__logo"
                                  src={spotifyLogo}
                                  alt="Spotify"
                                />
                              </a>
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>
                    <h5>{french ? e.creditsfr : e.creditseng}</h5>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        ref={modal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        view={view}
        french={french}
      />
    </>
  );
};

export default Disco;
