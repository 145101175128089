import React from "react";

const Links = ({ french }) => {
  return (
    <div className="container">
      <div className="container__page">
        <div className="links">
          {french ? (
            <>
              <h1>
                <span>Liens</span>
              </h1>
              <div>
                <h2>
                  <span>Sites de musiciens</span>
                </h2>
                <h3>
                  <a
                    href="https://www.danrosemusic.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    danrosemusic.com
                  </a>
                  <span> | </span>
                  site du guitariste américain Dan Rose
                </h3>
                <h3>
                  <a
                    href="http://brice.wassy.free.fr/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    brice.wassy.free.fr
                  </a>
                  <span> | </span>
                  site du batteur camerounais Brice Wassy
                </h3>
                <h2>
                  <span>Autres sites</span>
                </h2>
                <h3>
                  <a
                    href="https://www.jazzhot.net/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    jazzhot.net
                  </a>
                  <span> | </span>
                  site du magazine avec accès aux suppléments internet
                </h3>
                <h3>
                  <a
                    href="https://www.citizenjazz.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    citizenjazz.com
                  </a>
                  <span> | </span>
                  Chroniques, forums sur le jazz et aux musiques improvisées
                </h3>
                <h3>
                  <a
                    href="http://africultures.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    africultures.com
                  </a>
                  <span> | </span>
                  Toutes les cultures africaines
                </h3>
              </div>
            </>
          ) : (
            <>
              <h1>
                <span>Links</span>
              </h1>
              <div>
                <h2>
                  <span>Sites of fellow musicians</span>
                </h2>
                <h3>
                  <a
                    href="https://www.danrosemusic.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    danrosemusic.com
                  </a>
                  <span> | </span>
                  American guitarist Dan Rose
                </h3>
                <h3>
                  <a
                    href="http://brice.wassy.free.fr/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    brice.wassy.free.fr
                  </a>
                  <span> | </span>
                  Cameroonian drummer Brice Wassy
                </h3>
                <h2>
                  <span>Other sites of interest</span>
                </h2>
                <h3>
                  <a
                    href="https://www.jazzhot.net/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    jazzhot.net
                  </a>
                  <span> | </span>
                  Site of the French magazine with Internet bonus
                </h3>
                <h3>
                  <a
                    href="https://www.citizenjazz.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    citizenjazz.com
                  </a>
                  <span> | </span>
                  Reviews, forums on jazz and improvised music
                </h3>
                <h3>
                  <a
                    href="http://africultures.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    africultures.com
                  </a>
                  <span> | </span>
                  All African cultures
                </h3>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Links;
