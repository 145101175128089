import SlideshowHome from "../components/SlideshowHome";

const Home = ({ french }) => {
  return (
    <div className="container">
      <div className="container--home">
        <SlideshowHome />
      </div>
    </div>
  );
};

export default Home;
