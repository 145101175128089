import { useState } from "react";
import "./styles/App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./containers/Header";
import Navigation from "./components/Navigation";
import Home from "./containers/Home";
import Bio from "./containers/Bio";
import Press from "./containers/Press";
import Photos from "./containers/Photos";
import Concerts from "./containers/Concerts";
import GuestBook from "./containers/GuestBook";
import Links from "./containers/Links";
import Pro from "./containers/Pro";
import Contact from "./containers/Contact";
import Footer from "./containers/Footer";
import Disco from "./containers/Disco";

function App() {
  const [french, setFrench] = useState(true);

  return (
    <Router>
      <Header setFrench={setFrench} french={french} />
      <div className="container--main">
        <Navigation french={french} />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/bio">
            <Bio french={french} />
          </Route>
          <Route path="/disco">
            <Disco french={french} />
          </Route>
          <Route path="/press">
            <Press french={french} />
          </Route>
          <Route path="/photos">
            <Photos french={french} />
          </Route>
          <Route path="/concerts">
            <Concerts french={french} />
          </Route>
          <Route path="/guestbook">
            <GuestBook french={french} />
          </Route>
          <Route path="/links">
            <Links french={french} />
          </Route>
          <Route path="/pro">
            <Pro french={french} />
          </Route>
          <Route path="/contact">
            <Contact french={french} />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
