import React from "react";
import { useHistory } from "react-router-dom";

const Item = ({ title, page }) => {
  const history = useHistory();

  return (
    <div onClick={() => history.push(page)} className="nav__list__item">
      <h3>{title}</h3>
    </div>
  );
};

export default Item;
