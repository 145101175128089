import piano from "../images/piano/pianoIcon.svg";

const usersMessages = [
  {
    name: "Jean-Paul",
    date: "28 Novembre 2018",
    content: `Bonjour Madame, je connaissais votre musique par vos enregistrements, et voila que j'ai déniché votre "Jazzy : 7 pièces faciles pour le piano". Je l'ai simplement laissé sur le pianote la maison, et aujourd'hui, Raphaël, mon petit garçon de 9 ans s'est mis spontanément à déchiffrer ! Voila, c'est tout : des petites graines qui ne demandent qu'à pousser ; des notes bleues qui volent où elles veulent :-) J'ai pensé que cela vous ferait plaisir de le savoir. Alors Merci pour la musique !`,
  },
  {
    name: "Frédéric Vauthier",
    date: "6 Juin 2013",
    content:
      "Bonjour! j'ai découvert votre interview, votre site et votre excellente musique sur le site de globradio, j'ai étudié à l'American school of modern music de paris, et j'ai pris beaucoup de plaisir à vous écouter raconter votre parcours. Je vous écris pour vous dire toute mon admiration, merci pour ces bonnes vibrations!",
  },
  {
    name: "garance denaux",
    date: "20 Mars 2011",
    content:
      "Merci Claudine pour ta musique qui réchauffe nos coeurs. garance en Provence",
  },
  {
    name: "Florence Tsagué",
    date: "28 Juin 2010",
    content:
      "Bonjour Claudine, ravie d´avoir fait ta connaissance au Cameroun en Juin. Merci pour la musique que je ne tarderai pas à écouter. Cordialement Florence",
  },
  {
    name: "Anoh constance",
    date: "11 Mai 2009",
    content:
      "c'est constance de côte d'ivoire j'espère que tu reviendras dans mon pays voir la richesse culturelle nous avons 66 ethnies chacune avec sa particularité j insiste parce que tu ne connais que le caméroun et le bénin porte toi bien et à bientôt ",
  },
  {
    name: "chris",
    date: "8 Février 2009",
    content:
      "Pour les amateurs de jazz et de piano vous êtes un véritable inspiration. Nous avons pris grand plaisir a consulter cette page nous regrettons cependant la difficulté de trouver les cd de récital.",
  },
  {
    name: "daniele , corine, alain",
    date: "11 Septembre 2007",
    content:
      "Ta musique nous reunit ... On attend avec impatience la sortie de ton prochain CD , dont nous avons entendu quelques premices prometteurs ... L'experience du solo te va bien et nous t'encourageons dans ton projet !",
  },
  {
    name: "MAMPEY",
    date: "14 Juillet 2007",
    content:
      "Your work is wounderful, the songs are execellent, stay strong and best wishes. cheers",
  },
  {
    name: "géraldine célérier",
    date: "16 Septembre 2006",
    content:
      "Salut Claudine, félicitations pour toutes les musiques que tu as créer ces dernières années!!! Je trouve que c`est très élégant et fin ce que j`ai entendu sur my space. En tous cas, je suis a Québec depuis le début de l`année avec ma fille Tamara de sept ans. Au plaisir de te revoir , ré-entendre et jouer un jour... Géraldine",
  },
  {
    name: "Pierre",
    date: "9 Septembre 2006",
    content: `Bonjour, on ne se connais pas , je suis Pierre Mangeard, je suis tombé sur ce site par hasard et ca ma fait plaisir de voir la "face" a Steve !!!Dailleur salut steve ! En tout cas jolie music, meme si c court .`,
  },
  {
    name: "Andrea",
    date: "15 Juillet 2006",
    content: `Toutes mes félicitations pour ce site. Très intéressant, j'ai pris plaisir à visiter ses pages.`,
  },
  {
    name: "dag jack",
    date: "16 Juin 2006",
    content: `Salut claudine, Merci pour ton passage à Cotonou.Ton atelier me permet d'enrichir ma musique. J'aime beaucoup ton site.Nous avons reçu ce que tu nous as envoyé à Coyonou. Merci ,merci beaucoup.`,
  },
  {
    name: "bruno bass",
    date: "3 Juin 2006",
    content: `je te tire chapeau pour ce site merveilleux.Et j'aimerais à faire que toi.Tu es vraiment adorable.Bisou`,
  },
  {
    name: "clara",
    date: "2 Avril 2006",
    content: `Toutes mes félicitations pour ce site. Très intéressant, j'ai pris plaisir à visiter ses pages. `,
  },
  {
    name: "François Claudine",
    date: "21 Novembre 2005",
    content: `Et oui,je m'appelle aussi Claudine François... nous ne sommes pas seules ! Bravo pour votre son sublime et merci de regarder une fois mon site que vous faites reculer à la troisième page sur Google... Allez, sans rancune, entre homonymes on se comprend! Bien à vous , Claudine François`,
  },
  {
    name: "marcel boum",
    date: "08 Juin 2005",
    content: `boujour claudine, très jolie votre site,je ne manquerai pas pour faire un lien avec le lion indomptable bisous`,
  },
  {
    name: "léo mérie",
    date: "26 Mai 2005",
    content: `je viendrais bien vous rejoindre sur du monk le 04 juin je suis à paris, bises de la mer saléeeeee, shuffle boil, green chimneys, by ya & co...... `,
  },
  {
    name: "Kiki",
    date: "17 Mai 2005",
    content: `Oh! il est bien beau ce nouveau site...et tu as plein de concerts en perspective. J'ai vu que tu devais venir jouer à Luxembourg ville, on viendra t'écouter. Kiki`,
  },
];

const GuestBook = ({ french }) => {
  return (
    <div className="container">
      <div className="guestbook">
        {french ? (
          <div className="guestbook__title">
            <h1>Livre d'or</h1>
            <p>
              Si vous souhaitez laisser un message à Claudine veuillez écrire à{" "}
              <a href="mailto:contact@claudinefrancois.com">
                contact@claudinefrancois.com
              </a>{" "}
              sans oublier de préciser votre nom.
            </p>
          </div>
        ) : (
          <div className="guestbook__title">
            <h1>Guest book</h1>
            <p>
              If you want to leave a message for Claudine you can send it to{" "}
              <a href="mailto:contact@claudinefrancois.com">
                contact@claudinefrancois.com
              </a>{" "}
              with your name.
            </p>
          </div>
        )}
        <div className="piano">
          <img src={piano} alt="piano icon" />
        </div>
        {usersMessages.map((e, i) => {
          return (
            <div className="guestbook__messages" key={i}>
              <h2>♫ - {`${e.name} le ${e.date}`}</h2>
              <p>{e.content}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GuestBook;
