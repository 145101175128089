import Slideshow from "../components/Slideshow";

const Photos = ({ french }) => {
  return (
    <div className="container">
      <div className="container--page">
        <Slideshow />
      </div>
    </div>
  );
};

export default Photos;
