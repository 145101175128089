import pianorouge from "../images/pianorouge.jpg";

import Item from "./Item";

const Navigation = ({ french }) => {
  return (
    <div className="nav__container">
      <div className="nav__list">
        <div className="nav__list--img">
          <img src={pianorouge} alt="Claudine François" />
        </div>
        <Item title={french ? "Accueil" : "Home"} page="/" />
        <Item title={french ? "Biographie" : "Biography"} page="/bio" />
        <Item title={french ? "Discographie" : "Discography"} page="/disco" />
        <Item title={french ? "Presse" : "Press"} page="/press" />
        <Item title="Photos" page="/photos" />
        <Item title="Concerts" page="/concerts" />
        <Item title={french ? "Livre d'or" : "Guest Book"} page="/guestbook" />
        <Item title={french ? "Liens" : "Links"} page="/links" />
        <Item title={french ? "Espace Pro" : "Pro Area"} page="/pro" />
        <Item title="Contact" page="/contact" />
      </div>
    </div>
  );
};

export default Navigation;
